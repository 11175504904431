
<template>
  <v-container fluid fill-height>
    <v-layout align-center justify-center>
      <form ref="form" @submit.prevent="google_auth()">
        <v-btn x-large type="submit" class="mt-4" color="primary" value="log in"
          >Sign in with Google</v-btn
        >
      </form>
    </v-layout>

    <v-snackbar v-model="error" color="red accent-2">
      {{ errorMessage }}
      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="error = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import { User } from "../model/requests_schema.js";
export default {
  name: "Login",
  data() {
    return {
      error: false,
      errorMessage: "",
      username: "",
      password: "",
    };
  },
  methods: {
    ...mapActions(["login"]),
    async submit() {
      const user = User(this.username, this.password);
      try {
        await this.login(user);
        this.$router.push("/");
        this.error = false;
      } catch (error) {
        this.error = true;
        this.errorMessage = error;
      }
    },
    google_auth() {
      this.$router.push({
        redirect: (window.location.href = new URL(
          window.VUE_APP_AUTH_ENDPOINT,
          window.VUE_APP_BACKEND_URL
        ).href),
      });
    },
  },
};
</script>
